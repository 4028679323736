<template>
  <v-container :style="$vuetify.breakpoint.md ? 'max-width: 1000px' : ''">
    <base-material-card
      id="tattooer-list"
      :title="$t('list', { name: $tc('tattooer', 2) })"
      icon="mdi-account-edit"
    >
      <InvoiceForm
        v-if="invoice"
        :invoice="invoice"
        @validated="update"
        @cancel="$router.back()"
      />
    </base-material-card>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewInvoiceForm",
  components: {
    InvoiceForm: () => import("@/components/invoices/Form"),
  },
  data() {
    return {
      invoice: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("invoices", ["getInvoice", "editInvoice"]),
    fetch() {
      this.getInvoice(this.$route.params.id).then((response) => {
        this.invoice = response;
      });
    },
    update() {
      this.editInvoice({
        id: this.$route.params.id,
        formData: this.invoice,
      }).then(() => {
        this.$alert(this.$t("save_ok"));
        this.$router.back();
      });
    },
  },
};
</script>